import React from "react";
import CardGroup from 'react-bootstrap/CardGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CardAndText from "./CardAndText";
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used

function Home() {
    const checkImg = <FontAwesomeIcon icon={solid("circle-check")} size="10x" />;
    const trainImg = <FontAwesomeIcon icon={solid("database")} size="10x" />;
    const findImg = <FontAwesomeIcon icon={solid("magnifying-glass")} size="10x" />

    const webImg = <FontAwesomeIcon icon={solid("globe")} size="10x" />
    const codeImg = <FontAwesomeIcon icon={solid("code")} size="10x" />

    const trainTxt = "No more excessive fine-tuning and database populating. Our intelligent agents actively interact with environments to learn knowledge and skills, so you don't have to.";
    const trainTitle = "Train Less";

    const checkTxt = "State of the art memory structures and tools allow our AI to accomplish a higher complexity of tasks compared to regular chat platforms.";
    const checkTitle = "Accomplish More"

    const explainTxt = "Our AI systems are built to be as transparent as possible: View your agent's skills, memories and thought processes in plain language!";
    const explainTitle = "Understand Why";

    const surfTxt = "Shop online, research info or send emails - Just state the errand and our web assistants will figure it out.";
    const surfTitle = "Web Assistant"

    const codeTxt = "Highly adaptable code assistant that reasons across libraries and files. No more struggling with obscure languages and poor documentation.";
    const codeTitle = "Code Assistant"

    return (
        <div className="m-5">
            
            <h3 className="display-6">
                At PlethorAI, we create the future of AI systems that automate a wide variety of complex tasks, giving back humans their precious time.
            </h3>

            <h1>Intelligent Agents Redefined</h1>
            <CardGroup>
                        <CardAndText img={trainImg} txt={trainTxt} title={trainTitle} bg="danger"/>
                        <CardAndText img={checkImg} txt={checkTxt} title={checkTitle} bg="success"/>
                        <CardAndText img={findImg} txt={explainTxt} title={explainTitle} bg="primary"/>
            </CardGroup>
            <h1>Our Products</h1>
            Preview Coming Soon!
            <CardGroup>
                        <CardAndText img={webImg} txt={surfTxt} title={surfTitle} bg="info"/>
                        <CardAndText img={codeImg} txt={codeTxt} title={codeTitle} bg="warning"/>
            </CardGroup>
            
        </div>
    )
}

export default Home;