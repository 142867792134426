import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  const year = new Date().getFullYear();
  return (
    <footer>
      Copyright ⓒ {year} PlethorAI. 
      {/* <Link to="/Acknowledgements">Acknowledgements</Link> */}
    </footer>
  );
}

export default Footer;
