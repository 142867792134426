import React from "react";

function Acknowledgements() {
    return(
        <div className="mt-3">
            <h2>Special thanks to</h2>

            <div>
                <h3>Images</h3>
                <ul>
                    {/* <li>
                        <a href="https://www.flaticon.com/free-icons/arbitrage" title="arbitrage icons">Arbitrage icons created by Freepik - Flaticon</a>
                    </li>
                    <li>
                        <a href="https://www.flaticon.com/free-icons/gambling" title="gambling icons">Gambling icons created by Good Ware - Flaticon</a>
                    </li>
                    <li>
                        <a href="https://www.flaticon.com/free-icons/smart-contracts" title="smart contracts icons">Smart contracts icons created by wanicon - Flaticon</a>
                    </li>
                    <li>
                        <a href="https://www.flaticon.com/free-icons/forex" title="forex icons">Forex icons created by Uniconlabs - Flaticon</a>
                    </li> */}
                </ul>
                
            </div>
            
        </div>
    )

}

export default Acknowledgements;