import React from "react";
import Card from 'react-bootstrap/Card';

function CardAndText(prop) {

    return (
            <Card className="m-2 p-3 border-0 text-bg-dark" bg={prop.bg}>
                <Card.Title><h3 className="text-center">{prop.title}</h3></Card.Title>
                <div className="row justify-content-center m-1">
                    {prop.img}
                </div>
                <Card.Text style={{textAlign: "justify"}}>
                    {prop.txt}
                </Card.Text>
            </Card>
    )
}

export default CardAndText;